:root {
  --white: #fff;
  --black: #000;
  --lightBlue: #add8e6;
  --midBlue: #005b94;
  --darkBlue: #003c7a;
  --lightGrey: #ededee;
  --midGrey: #dcdcdd;
  --darkGrey: #545455;
  :focus-visible {
    padding: 2px;
    border: 3px double var(--lightGrey);
    outline: 3px double var(--darkBlue);
    outline-offset: -2px;
  }
}

html {
  background-color: var(--lightGrey);
}

body {
  margin: 0% 7%;
}

a {
  padding: 2px;
  border: 3px double transparent;
  outline: 3px double transparent;
  outline-offset: -2px;
}
a:hover {
  padding: 0.75px;
  border: 3px double transparent;
  outline: 3px double transparent;
  outline-offset: -2px;
  font-style: italic;
}

h2 {
  text-decoration: underline double 1px;
  text-decoration-line: underline;
  text-decoration-style: double;
}

img {
  max-width: 300px;
  height: auto;
  border: thick double;
  border-radius: 28%;
  justify-content: center;
}

img.icon {
  max-width: 42px;
  height: auto;
  border: double;
  border-radius: 56%;
  border-color: white;
}

[role="banner"] {
  margin-top: 84px;
  padding: 84px;
  background-color: var(--midBlue);
  color: #fff;
  text-align: center;
    && h1 {
      margin-top: 42px;
    }
    && p {
      font-weight: 575;
    }
    && .Att {
      margin: 0px;
      padding: 0px;
      color: var(--midGrey);
      text-shadow: 1px 1px 9px var(--black);
      font-size: 75%;
      font-weight: 750;
      && a {
        color: var(--lightBlue);
      }
    }
}

[role="navigation"] {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  display: flex;
  float: left;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px;
  background-color: var(--darkBlue);
}

[role="menubar"] {
  margin: 1px;
  padding: 0%;
  background-color: var(--darkBlue);
  && a {
  height: 39px;
  }
}

[role="menuitem"] {
  display: flex;
  flex-wrap: nowrap;
  float: left;
  align-items: center;
  justify-content: center;
  height: 49px;
  width: 91px;
  color: var(--lightGrey);
  text-align: center;
  list-style-type: none;
  && a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: var(--lightGrey);
    text-decoration: none;
    font-weight: 555;
  }
  && a:hover {
    margin: 0.25px 0.25px 0px 0.75px;
    padding: 2.75px;
    border: 2px solid var(--lightGrey);
    outline: 2px solid var(--darkBlue);
    outline-offset: -4px;
  }
}

.App {
  clear: both;
  padding: 42px 7%;
  background-color: var(--white);
  color: var(--midBlue);
}

.About {
  margin: 42px 7%;
  padding: 42px 7%;
  border: 3px double #003c7a;
  background-color: var(--lightGrey);
  color: var(--darkBlue);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  text-align: center;
    p {
      line-height: 162%;
    }
}

.Card {
  margin: 14px 0px;
  padding: 7px 0px;
  border-bottom: 2.5px double var(--midGrey);
}

.Card-last {
  padding-bottom: none;
  border: none;
}

.App-footer {
  display: flex;
  color: var(--darkGrey); 
  && a {
    color:var(--darkBlue);
  }
}

.App-footer-l {
  width: 70%;
  float: left;
  color: var(--darkGrey); 
  && a {
    color: var(--darkBlue);
  }
}

.App-footer-r {
  width: 30%;
  float: left;
  text-align: right;
  color: var(--darkGrey); 
  && a {
    color: var(--darkBlue);
  }
}

@media only screen and (max-width: 1485px) {
  [role="menuitem"] {
    width: 84px;
  }
}

@media only screen and (max-width: 1305px) {
  [role="menuitem"] {
    width: 77px;
  }
}

@media only screen and (max-width: 1170px) {
  /* implement mobile nav */
  [role="navigation"] {
    ::after {
      position: relative;
      right: -100%;
      background-color: var(--lightGrey);
      border: 4px double var(--darkBlue);
      padding: 7px;
      color: var(--darkBlue);
      content: "Menu (WIP)";
    }
  }
  [role="menuitem"] {
    display: none;
  }
}

@media only screen and (max-width: 710px) {
  body {
    margin: 0% 3.5%;
  }
  img {
    max-width: 205px;
  }
  .App-header {
    margin-top: 42px;
    padding: 84px 42px 42px 42px;
  }
  .App {
    padding: 21px 3.5%;
  }
  .About {
    margin: 21px 3.5%;
    margin-bottom: 42px;
  }
  .App-footer {
    display: block;
    text-align: center;
    color: var(--darkGrey); 
    && a {
      color: var(--darkBlue);
    }
  }
  .App-footer-l {
    width: 100%;
    text-align: center;
    color: var(--darkGrey); 
    && p {
      margin-top: 16px;
    }
    && a {
      color: var(--darkBlue);
    }
  }
  .App-footer-r {
    width: 100%;
    text-align: center;
    color: var(--darkGrey); 
    && p {
      margin-top: 0px;
    }
    && a {
      color: var(--darkBlue);
    }
  }
}